import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import axios from 'axios';

import { useMsal, useAccount, AuthError } from "@azure/msal-react";
import { silentRequest } from "../../authConfig";

//import * as signalR from "@microsoft/signalr";

import paginate from 'jw-paginate';

import { Pagination } from '@uifabric/experiments/lib/Pagination';

import CountUp from 'react-countup';

import { 
    //TextField, 
    //DetailsList,
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    //Selection,
    SelectionMode,
    //IColumn, 
    mergeStyleSets, 
    TooltipHost,
    //Fabric,
    ActionButton,
    Icon,    
    //ProgressIndicator,
    //Label,
    Spinner,
    SpinnerSize,
    CommandBar,
    Stack,
    DocumentCard,
    //DocumentCardActivity,
    DocumentCardTitle,
    DocumentCardDetails,
    DocumentCardLogo,
    //DocumentCardStatus,
    DocumentCardType,
    Text,
    DefaultButton
    //Pagination
    //initializeIcons
  } from '@fluentui/react';
  
import { 
    getFileTypeIconProps, 
//FileIconType 
} from '@uifabric/file-type-icons';

const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

const homeStyles = {    
    //width: '208',
    //height: '10vh',
    //width: 185,
    //height: 70,
    //boxSizing: 'border-box',
    //border: '1px solid #eee',
    //overflowY: 'auto',
    //paddingTop: '10vh',    
    //background: '#FFFFFF',
    //background: '#106ebe',
    border: 0,
    /* paddingTop: '2%',
    paddingLeft: '2%',
    paddingRight: '2%', */
    margin: 0,
    //minHeight: '100vh',
    width: '100%',
    background: '#FAF9F8'
    
}

//ProgressIndicator
//const centerItem = { display: "flex", alignItems: "center", justifyContent: "center" };
//Progress Indicator Setting
//const intervalDelay = 100;
//const intervalIncrement = 0.01;

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
    },
    fileIconCell: {
    textAlign: 'center',
    selectors: {
        '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
        },
    },
    },
    fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
    },
    controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    },
    exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
    },
    selectionDetails: {
    marginBottom: '20px',
    },
});
/* 
const shimmeredDetailsListProps = {
renderedWindowsAhead: 0,
renderedWindowsBehind: 0,
};
*/

const cardStyles = {
    cardStyles: {
        root: {
        background: 'white',      
        //padding: 20,
        //marginRight: '50%',
        //marginRight: '40px',
        //borderTop: '5px solid #0078d4',
        borderLeft: '5px solid #0078d4',
        width: '30%',
        minWidth: '315px',
        //maxWidth: '415px',
        maxWidth: '424px',
        height: '130px',
        //margin: 'auto',
        //padding: '0 10% 0 10% ',
        //display: 'flex',
        //alignItems: 'center',
        //justifyContent: 'center',

        }
    },
    header: {
        root: {
        fontSize: 20,
        fontWeight: 'bold',
        //paddingTop: '5%',
        paddingTop: '20px',
        textAlign: 'center',
        //color: '#696969'
        }
    },
    amount: {
        root: {
        fontSize: 30,
        //paddingBottom: 20,
        //paddingBottom: '8%',
        paddingBottom: '25px',
        //paddingTop: '20%',
        //paddingRight: '20%',
        textAlign: 'center'
        }
    },
};

function formatDateTime(date) {
    //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        minute = '' + d.getMinutes(),
        second = '' + d.getSeconds()

    //console.log(d.toString());

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (minute.length < 2) 
        minute = '0' + minute;
    if (second.length < 2) 
        second = '0' + second;

    return [year, month, day, hour, minute, second].join('');
}

const Documents = (props) => {

    console.log('Home render!!!');

    const history = useHistory();

    const { instance, accounts } = useMsal();
    //const account = useAccount(accounts[0] || {});
    const account = instance.getAllAccounts()[0];
    
    //let token = b2cauth.getAccessToken();
    //console.log('Bearer', token);

    //const token = props.token;    
    //const token = props.token;    
    //

    //const api_key = props.api_key;
    //console.log('Api-Key:', props.api_key);

    //const items = [];
    const [documents, setDocuments] = useState([]);
    const [items, setItems] = useState([]);
    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(true);
    const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
    //const [clickDelete, setClickDelete] = useState(false);
    const [pageData, setPageData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
/* 
    const [pageData, setPageData] = useState({
      totalItems: '',
      currentPage: '',
      pageSize: '',
      totalPages: '',
      startPage: '',
      endPage: '',
      startIndex: '',
      endIndex: '',
      pages: [] 
    });
 */
    //const [percentComplete, setPercentComplete] = useState(0);

    //CommandBars Items
    const [documentType, setDocumentType] = useState({ key: 'ALL', text: 'เอกสารทุกประเภท' });

    //Display Summary
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [totalEsigns, setTotalEsigns] = useState(0);
    const [totalDsigns, setTotalDsigns] = useState(0);
    

    const getMonth = (goBack) => {
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 
      let d = new Date();
      //console.log('new Date(): ', d);

      let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);
      
      //d.setMonth(d.getMonth() - goBack);
      //console.log(d.toLocaleDateString());
      //console.log(d.getMonth());

      let year = adjustDate.getFullYear() + 543;

      return { 
        month: adjustDate.getMonth(), 
        year: adjustDate.getFullYear(), 
        key: adjustDate.getMonth() + 1, 
        text: monthNames[adjustDate.getMonth()] + ' ' + year 
      };
      //return monthNames[d.getMonth()] + ' ' + year;
      //let m = d.getMonth();
/* 
      let d;
      let year;
      let _month = [];
      

      for (let i = 0; i < 6; i++) {
        d = new Date();
        d.setMonth(d.getMonth() - i);
        year = d.getFullYear() + 543;
        _month.push(monthNames[d.getMonth()] + ' ' + year);
      }
*/      

      //console.log(_month);
        
      //return _month;
    }

    const [queryMonth, setQueryMonth] = useState({ key: getMonth(0).key, text: 'เดือนนี้' });

    const getFrom = (month, year) => {
      //let date = new Date();
      let _firstDay = new Date(year, month, 1);
      //let _lastDay = new Date(year, month + 1, 1);
      console.log('First day: ', formatDate(_firstDay));

      return formatDate(_firstDay);
      //setLastDay(formatDate(_lastDay));

      
      //console.log('Last day: ', formatDate(_lastDay));
    }

    const getTo = (month, year) => {
      //let date = new Date();
      //let _firstDay = new Date(year, month, 1);
      let _lastDay = new Date(year, month + 1, 1);
      console.log('Last day: ', formatDate(_lastDay));

      return formatDate(_lastDay);
      //setLastDay(formatDate(_lastDay));

      //console.log('Fist day: ', formatDate(_firstDay));
      
    }

    const formatDate = (d) => {
      //let d = new Date(date),
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      let year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('-');
    }

    function convertDateToUTC() {
      var date = new Date(); 
      var now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
      date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

      return new Date(now_utc).toISOString();

    }

    const [from, setFrom] = useState(getFrom(getMonth(0).month, getMonth(0).year));
    const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));

    const commandBarItems = [
      {
        key: documentType.key,
        text: documentType.text,
        cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
        iconProps: { iconName: 'Page' },
        subMenuProps: {
          items: [
            {
              key: 'ALL',
              text: 'เอกสารทุกประเภท',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('เอกสารทุกประเภท');
                setDocumentType({ key: 'ALL', text: 'เอกสารทุกประเภท' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: 'RECEIPT-TAXINVOICE',
              text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน/ใบกำกับภาษี');
                setDocumentType({ key: 'RECEIPT-TAXINVOICE', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
/* 
            {
              key: 'RECEIPT-TAXINVOICE-ABB',
              text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ');
                setDocumentType({ key: 'RECEIPT-TAXINVOICE-ABB', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
  */           
            {
              key: 'RECEIPT',
              text: 'ใบเสร็จรับเงิน',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเสร็จรับเงิน');
                setDocumentType({ key: 'RECEIPT', text: 'ใบเสร็จรับเงิน' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: 'DEBIT-NOTE',
              text: 'ใบเพิ่มหนี้',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบเพิ่มหนี้');
                setDocumentType({ key: 'DEBIT-NOTE', text: 'ใบเพิ่มหนี้' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
            },
            {
              key: 'CREDIT-NOTE',
              text: 'ใบลดหนี้',
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('ใบลดหนี้');
                setDocumentType({ key: 'CREDIT-NOTE', text: 'ใบลดหนี้' });

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
            },
          ],
        },
        
      },
      {
        key: queryMonth.key,
        text: queryMonth.text,
        iconProps: { iconName: 'Calendar' },
        //href: 'https://developer.microsoft.com/en-us/fluentui',
        subMenuProps: {
          items: [
            {
              key: getMonth(0).key,
              text: getMonth(0).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {               
                console.log('Selected month: ', getMonth(0).key);
                setQueryMonth({ key: getMonth(0).key, text: getMonth(0).text });
                //setFirstLastDay(getMonth(0).month, getMonth(0).year);
                setFrom(getFrom(getMonth(0).month, getMonth(0).year));
                setTo(getTo(getMonth(0).month, getMonth(0).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(1).key,
              text: getMonth(1).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(1).key);
                setQueryMonth({ key: getMonth(1).key, text: getMonth(1).text });
                //setFirstLastDay(getMonth(1).month, getMonth(1).year);
                setFrom(getFrom(getMonth(1).month, getMonth(1).year));
                setTo(getTo(getMonth(1).month, getMonth(1).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(2).key,
              text: getMonth(2).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(2).key);
                setQueryMonth({ key: getMonth(2).key, text: getMonth(2).text });
                //setFirstLastDay(getMonth(2).month, getMonth(2).year);
                setFrom(getFrom(getMonth(2).month, getMonth(2).year));
                setTo(getTo(getMonth(2).month, getMonth(2).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(3).key,
              text: getMonth(3).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(3).key);
                setQueryMonth({ key: getMonth(3).key, text: getMonth(3).text });
                //setFirstLastDay(getMonth(3).month, getMonth(3).year);
                setFrom(getFrom(getMonth(3).month, getMonth(3).year));
                setTo(getTo(getMonth(3).month, getMonth(3).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(4).key,
              text: getMonth(4).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log('Selected month: ', getMonth(4).key);
                setQueryMonth({ key: getMonth(4).key, text: getMonth(4).text });
                //setFirstLastDay(getMonth(4).month, getMonth(4).year);
                setFrom(getFrom(getMonth(4).month, getMonth(4).year));
                setTo(getTo(getMonth(4).month, getMonth(4).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
            {
              key: getMonth(5).key,
              text: getMonth(5).text,
              iconProps: { iconName: 'Calendar' },
              onClick: () => {
                console.log(getMonth(5).key);
                setQueryMonth({ key: getMonth(5).key, text: getMonth(5).text });
                //setFirstLastDay(getMonth(5).month, getMonth(5).year);
                setFrom(getFrom(getMonth(5).month, getMonth(5).year));
                setTo(getTo(getMonth(5).month, getMonth(5).year));

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
                setTotalEsigns(0);
                setTotalDsigns(0);
              },
              //['data-automation-id']: 'newEmailButton', // optional
            },
          ]
        }
      },      
      {
        key: 'download',
        text: 'ดาวน์โหลด',
        iconProps: { iconName: 'Download' },
        onClick: () => {
          console.log('Download');
          downloadZip();
        },
      },
      {
        key: 'refresh',
        text: 'รีเฟรช',
        iconProps: { iconName: 'Refresh' },
        onClick: () => {
          console.log('Refresh');

          setLoadDataComplete(false);
          setDocuments([]);
          setItems([]);
          setGetNewDocuments(true);

          setTotalDocuments(0);
          setTotalEsigns(0);
          setTotalDsigns(0);
        },
      },
    ];

    const toThaiDateString = (isoDateTime) => {
      let date = '';
      date = new Date(isoDateTime);
      
      //console.log(dateTime.toLocaleDateString() + ' ' + dateTime.toLocaleTimeString());
/* 
      let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
      ];
 */
      let year = date.getFullYear() + 543;      
      //let month = monthNames[date.getMonth()];
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let numOfDay = date.getDate().toString().padStart(2, '0');

      let hour = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let second = date.getSeconds().toString().padStart(2, '0');

      return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
    }

    const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';
  
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  
      const i = Math.floor(Math.log(bytes) / Math.log(k));
  
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
      /* 
    useEffect(() => {
      if (account) {
          instance.acquireTokenSilent({
              scopes: ["User.Read"],
              account: account
          }).then((response) => {
              if(response) {
                  callMsGraph(response.accessToken).then((result) => setApiData(result));
              }
          });
      }
    }, [account, instance]);
         */

    const getTotalProcessingDocuments = () => {
      console.log('Get total processing document...');
      instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

        //axios.get('https://etax.azure-api.net/company/b5cf38c9-5e52-4bd3-b737-caf5c6d30635')
        //axios.get('https://etax.azure-api.net/company/' + userId)
        //console.log(tokenResponse);
        //setUserFirstName(tokenResponse.idTokenClaims.given_name);

        //axios.get(API_URL + '/queues/processing/total', 
        axios.get(ADMIN_API_URL + '/etax/jobs/pdf/processing/total', 
          {
            headers: { 
                'Authorization': 'Bearer ' + tokenResponse.accessToken
            } 
          })
          .then((response) => {

            console.log('Total processing jobs response: ', response);

            if (response.data[0]) {

                //console.log(JSON.stringify(response.data));
                console.log('Total processing jobs: ', response.data[0]);
                
                setTotalProcessingDocuments(response.data[0]);
                
            } else {                
                
                setTotalProcessingDocuments(0);
            }
              
          }, (error) => {
              console.log(error);
              
          });

        }).catch(error => {           

          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

/* 
          if (error.errorMessage.indexOf("interaction_required") !== -1) {
              instance.acquireTokenRedirect(silentRequest);
          }
 */

        });
    }

    

    useEffect(() => {
        console.log('userEffect Call!');

        //console.log('UTC now: ', convertDateToUTC());

        

    }, []);

    useEffect(() => {
      console.log('userEffect getNewDocuments Call!');

      //let certificateName = {};

      //token = b2cauth.getAccessToken();

      //b2cauth.run();

      instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);       

            
        //axios.get('https://ws.leceipt.com/documents', 
        //axios.get(API_URL + '/documents?type=ETAX&subtype=' + documentType.key + '&fileextension=xml&from=' + from + '&to=' + to, 
        axios.get(ADMIN_API_URL + '/documents/all/all/all/all' + '?from=' + from + '&to=' + to, 
        {
          headers: { 
            'Authorization': 'Bearer ' + tokenResponse.accessToken
          } 
        })
        .then((response) => {

          console.log(response);
          console.log('Docs number: ', response.data.length);

          setTotalDocuments(response.data.length);

          //set page
          console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
          setPageData(paginate(response.data.length, currentPage, pageSize, 10));
/* 
          const _docs = [];

          response.data.forEach(doc => {
            let documentName = '';
            let fileSize = formatBytes(doc.fileSize);  
            let createdTime = toThaiDateString(doc.createdTime);;

            if (doc.type === 'ETAX') {
              documentName = doc.data.name + ' ' + doc.data.number                            
              
            }

            _docs.push({
              key: doc.id,
              fileName: doc.fileName + '.' + doc.fileExtension,
              name: documentName,
              value: doc.fileName,
              //iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
              fileExtension: doc.fileExtension,
              createdBy: doc.createdBy,
              createdTime: createdTime,
              createdTimeValue: doc.createdTime,
              fileSize: fileSize,
              fileSizeRaw: doc.fileSize,
              type: doc.type,
              signature: doc.signatures[0].certificateName,
              //signature: certificateName[doc.certificateId],
              //deliveryStatus: 'รอระบบนำส่ง'
    
            });
                          
          });

          setItems(_docs);
          setLoadDataComplete(true);
          //setClickDelete(false);
          setGetNewDocuments(false);
            */

          setDocuments(response.data);

          const _docs = [];
          
          if (response.data.length > 0) {
            
            for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, response.data.length); i++) {
              let documentName = '';
              //let fileSize = formatBytes(response.data[i].fileSize);  
              let fileSize = formatBytes(response.data[i].file.size);  
              let createdTime = toThaiDateString(response.data[i].createdTime);
              
         
              _docs.push({
                key: response.data[i].id,                
                fileName: response.data[i].file.displayName,
                fileExtension: response.data[i].file.extension,
                createdBy: response.data[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: response.data[i].createdTime,
                fileSize: fileSize,
                //fileSizeRaw: response.data[i].fileSize,
                fileSizeRaw: response.data[i].file.size,                
                //signature: response.data[i].signatures[0].certificateName,   
                flow: response.data[i].flow,             
                status: response.data[i].status,
                type: response.data[i].type,
                
                
              });

                        
              
              
            }      

            let _totalEsigns = 0;
            let _totalDsigns = 0;

            for (let i = 0; i < response.data.length; i++) {

                if (response.data[i].type === 'electronic') {
                    _totalEsigns++;
                }

                if (response.data[i].type === 'digital') {
                    _totalDsigns++;
                }
                
            }

            setTotalEsigns(_totalEsigns);
            setTotalDsigns(_totalDsigns);       
            
        
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);


        }, (error) => {
            console.log(error);
        });
                
                


      }).catch(error => {
        
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        

      });

   

    }, [getNewDocuments]);
    
    useEffect(() => {

      if (documents.length > 0) {        

        const _docs = [];
        
        for (let i = (currentPage-1)*pageSize; i < Math.min(currentPage*pageSize, documents.length); i++) {
          let documentName = '';
          //let fileSize = formatBytes(documents[i].fileSize);  
          let fileSize = formatBytes(documents[i].file.size);  
          let createdTime = toThaiDateString(documents[i].createdTime);
          
         
          _docs.push({
            key: documents[i].id,            
            fileName: documents[i].file.displayName,            
            fileExtension: documents[i].file.extension,
            createdBy: documents[i].createdBy,
            createdTime: createdTime,
            createdTimeValue: documents[i].createdTime,
            fileSize: fileSize,           
            fileSizeRaw: documents[i].file.size,
            flow: documents[i].flow,             
            status: documents[i].status,
            type: documents[i].type,            
  
          });

                    
          
          
        }
        console.log('Docs data', _docs);

        
        
        
        setItems(_docs);
        //setLoadDataComplete(true);
        //setGetNewDocuments(false);
      }

    }, [currentPage]);
 /*
    useEffect(() => {
      if (!loadDataComplete) {
          const id = setInterval(() => {
              setPercentComplete((intervalIncrement + percentComplete) % 1);
             
              if (html != '') {
                  setPercentComplete((intervalIncrement + 100) % 1);
                  console.log('100%');
              }
              
          }, intervalDelay);
          return () => {
              clearInterval(id);
          };
      }
    });*/

    const download = (event, item) => {
      //console.log('Event ', event);
      //token = token;

      //b2cauth.run();

      instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

        //axios.get(API_URL + '/documents/' + item.key, {
        axios.get(ADMIN_API_URL + '/files/' + item.key, {
            headers: { 
              'Authorization': 'Bearer ' + tokenResponse.accessToken
            },
            responseType: 'blob' 
          })
          .then((response) => {
            console.log(response.headers);

            const time = formatDateTime(item.createdTimeValue);

            //console.log('item: ', item);

            let fileName = time + '-' + item.fileName;
                        
            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            
          }, (error) => {
            console.log(error);
          });

      }).catch(error => {
        /* 
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(tokenRequest)
        }
  */

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

/* 
        //if (error.errorMessage.indexOf("interaction_required") !== -1) {
        if (error.errorMessage.includes("AADB2C90077")) {
          instance.acquireTokenRedirect(silentRequest);
        }
 */

      });



    }

    const deleteFile = (event, item) => {
      //console.log('Event ', event);
      //token = b2cauth.getAccessToken();
      //b2cauth.run();

      //let confirm = window.confirm('กรุณายืนยัน การลบเอกสาร เลขที่ ' + item.key);

        if (true) {

            instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

                //axios.delete(API_URL + '/documents/' + item.key, 
                axios.delete(ADMIN_API_URL + '/documents/' + item.key, 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                }
                })
                .then((response) => {
                console.log(response);
                setLoadDataComplete(false);
                setGetNewDocuments(true);
                        
                }, (error) => {
                console.log(error);
                });

            }).catch(error => {
                /* 
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(tokenRequest)
                }
        */

                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
        /* 
                //if (error.errorMessage.indexOf("interaction_required") !== -1) {
                if (error.errorMessage.includes("AADB2C90077")) {
                instance.acquireTokenRedirect(silentRequest);
                }
        */
            });

        }



    }

    const openFile = (event, item) => {

        if (item.fileExtension === 'xml') {

            history.push(
                { 
                    pathname: '/documents/views/xml', 
                    state: {
                        item: item
                        //documentId: item.key,
                        //certificateName: item.signature
                    } 
                });

        } else {

            history.push(
                { 
                    pathname: '/documents/views/pdf', 
                    state: {
                        item: item
                        //documentId: item.key,
                        //certificateName: item.signature
                    } 
                });

        }

    }

    const onPageChange = (selectedPageIndex) => {
      console.log('selectedPageIndex: ', selectedPageIndex);
      setCurrentPage(selectedPageIndex+1);
    }

    const downloadZip = () => {
      //console.log('Event ', event);
      //token = token;

      //b2cauth.run();

      //console.log(API_URL + '/etax/files/' + documentType.key + '/xml?from=' + from + '&to=' + to);

      instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
/* 
        axios
          .get(API_URL + '/etax/documents?' + 
            '&type=ETAX' +
            '&subType=' + documentType.key +
            '&from=' + from +
            '&to=' + to +
            '&fileExtension=xml',    
      */       
        //axios.get(API_URL + '/etax/documents/' + documentType.key + '/xml?from=' + from + '&to=' + to,         
      axios.get(API_URL + '/etax/files/' + documentType.key + '/pdf?from=' + from + '&to=' + to,         
          {
            headers: { 
              'Authorization': 'Bearer ' + tokenResponse.accessToken
            },
            responseType: 'blob' 
          })
          .then((response) => {
            console.log(response.headers);
/*
            let headerLine = response.headers['Content-Disposition'];
            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"');
            let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);

            console.log('File name: ', filename);
*/
            let zipFileName = from.substr(0, 4) + from.substr(5, 2) + '-' + documentType.key + '-XML-Leceipt.zip';

            console.log('Zip file name: ', zipFileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', zipFileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
             
          }, (error) => {
            console.log(error);
          });

      }).catch(error => {
        /* 
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return instance.acquireTokenRedirect(tokenRequest)
        }
  */

        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

/* 
        //if (error.errorMessage.indexOf("interaction_required") !== -1) {
        if (error.errorMessage.includes("AADB2C90077")) {
          instance.acquireTokenRedirect(silentRequest);
        }
 */

      });



    }

    

    

  /* 
    const items = [
        {
            key: '1',
            name: 'file name',
            value: 'file name',
            iconName: 'https://static2.sharepointonline.com/files/fabric/assets/item-types/16/xml.svg',
            fileExtension: 'xml',
            modifiedBy: 'Krit',
            createdTime: '13/02/2021',
            createdTimeValue: 1613195285881,
            fileSize: 123,
            fileSizeRaw: 12345,
            type: 'e-Tax',
            signature: 'บริษัท ทดสอบ จำกัด',
            deliveryStatus: 'รอระบบนำส่ง',

        }
    ];
 */   
/* 
    const onRenderItemColumn = () => {
      if (column.fieldName === 'fieldName') {
        return <Link data-selection-invoke={true}>{"Edit"}</Link>;
      }
      return item[column.fieldName];

    }
 */
    const columns = [
      {
        key: 'column1',
        name: 'File Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: '',

        onRender: (item) => (
          <TooltipHost content={`ไฟล์ ${item.fileExtension}`}>
            {/* <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileExtension} file icon`} /> */}
            <Icon {...getFileTypeIconProps({ extension: item.fileExtension, size: 16, imageFileType: 'svg' }) } />
          </TooltipHost>
        ),

      },
      {
        key: 'column2',
        name: 'วันที่สร้าง',
        fieldName: 'createdTime',
        minWidth: 125,
        maxWidth: 125,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: '',
        data: 'number',
        onRender: (item) => {
          return <span>{item.createdTime}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Flow',        
        fieldName: 'flow',
        minWidth: 60,
        maxWidth: 60,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.flow}</span>;
        },
        isPadded: true,        
        
      },
      {
        key: 'column4',
        name: 'สถานะ',
        fieldName: 'status',
        minWidth: 60,
        maxWidth: 60,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.status}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'ประเภท',
        fieldName: 'type',
        minWidth: 60,
        maxWidth: 60,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span>{item.type}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column6',
        name: 'ชื่อไฟล์',        
        fieldName: 'fileName',
        minWidth: 140,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <ActionButton title="เปิดไฟล์" className={classNames.fileIconImg} onClick={(event) => {openFile(event, item)}} >{item.fileName}</ActionButton>;
        },
        isPadded: true,        
        
      },
      
      {
        key: 'column7',
        name: 'oid',
        fieldName: 'oid',
        minWidth: 80,
        maxWidth: 200,
        isResizable: true,
        //isSorted: true,
        //isSortedDescending: true,
        onColumnClick: '',
        data: 'string',
        onRender: (item) => {
          return <span style={{ display: 'block', textAlign: 'right' }}>{item.createdBy}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column8',
        name: '',
        fieldName: 'command',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        //isCollapsible: true,
        data: 'string',
        onColumnClick: '',
        onRender: (item) => {
          return (
/* 
            <ActionButton iconProps={
              {
                iconName: 'DownloadDocument',
  /* 
                styles: {
                    root: {
                        //fontSize: 20,
                        color: '#106ebe',
                    }
                }
                /
              }
            } title="ดาวน์โหลด" className={classNames.fileIconImg} onClick={(event) => {download(event, item)}} >
              ดาวน์โหลด
            </ActionButton>
 */
            <DefaultButton 
              text="ดาวน์โหลด"
/* 
              iconProps={
                {
                  iconName: 'DownloadDocument',  
                  styles: {
                    root: {
                        //fontSize: 20,
                        color: '#106ebe',
                    }
                  }
                }
                 
              } */
              title="ดาวน์โหลด" 
              onClick={(event) => {download(event, item)}} 
            >
                ดาวน์โหลด
            </DefaultButton>


          )
        },
      },
      {
        key: 'column10',
        name: '',
        fieldName: 'command',
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: '',
        onRender: (item) => {
          //console.log('Item: ', item);

          
            return <ActionButton iconProps={
              {
                iconName: 'Delete',
                //iconName: 'PageRemove',

                styles: {
                    root: {
                        //fontSize: 20,
                        color: '#D65633',
                    }
                }
                
              }
            } title="ลบ" className={classNames.fileIconImg} onClick={(event) => {deleteFile(event, item)}} >
              ลบ
            </ActionButton>;
           
        },
       
      },
      
    /*   {
        key: 'column8',
        name: 'สถานะการนำส่ง',
        fieldName: 'deliveryStatus',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: '',
        onRender: (item) => {
          return <span>{item.deliveryStatus}</span>;
        },
      }, */
    ];
    
    const isCompactMode = false;
    //const isModealSelection = false;
    //const annoucedMessage = undefined;
    
    

    return (
        <div style={homeStyles}>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>เอกสารทั้งหมด</h2>
              {/* <h4>( สำหรับส่งให้กรมสรรพากร )</h4> */}
              {/* <br/> */}
            </center>

            
              <Stack 
                horizontal 
                horizontalAlign="center"
                //wrap 
/* 
                styles={{
                  root: {
                    background: '#FAF9F8',                    
                  } 
                }} 
                tokens={{ childrenGap: '2%' }}
 */
                tokens={{ childrenGap: '30px'}}
              >

                  <DocumentCard
                    aria-label="จำนวนเอกสาร"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'Documentation' } } title="จำนวนเอกสาร" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="จำนวนเอกสาร"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalDocuments} ใบ</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={totalDocuments} separator=','  duration={1} /> ใบ</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
                    
                
                <DocumentCard
                    aria-label="Electronic Sign"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'Documentation' } } title="จำนวนเอกสาร" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="Electronic Sign"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalVat.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={totalEsigns} separator=',' decimals={0} duration={1} /> ใบ</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
                
                <DocumentCard
                    aria-label="Digital Sign"
                    styles={cardStyles.cardStyles}                    
                    type={DocumentCardType.compact}
                    //onClickHref="http://bing.com"
                  >
                    <DocumentCardLogo { ...{ logoIcon: 'Documentation' } } title="จำนวนเอกสาร" />
                      {/* <div className={conversationTileClass}> */}
                    <DocumentCardDetails>
                      <DocumentCardTitle
                        title="Digital Sign"
                        //shouldTruncate
                        styles={cardStyles.header}
                      />                      
                      {/* <Text styles={cardStyles.amount}>{totalGrand.toLocaleString(undefined, {maximumFractionDigits:2, minimumFractionDigits:2})} บาท</Text> */}
                      <Text styles={cardStyles.amount}><CountUp end={totalDsigns} separator=',' decimals={0} duration={1} /> ใบ</Text>
                      {/* </div>  */} 
                    </DocumentCardDetails>
                  </DocumentCard>
              </Stack>
            </Stack>
            {/* <br /> */}            
            {/* { true && <div className="ms-Grid" dir="ltr" style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, marginLeft: '5%', marginRight: '5%' }}> 
                
                <ProgressIndicator label="กำลังดำเนินการ" description="สร้างเอกสารและลงลายเซ็นดิจิทัล" percentComplete={percentComplete} style={centerItem} />
                <br />
            </div> } */}

            { (totalProcessingDocuments > 0) && <div>
              {/* <Label>Spinner with label positioned below</Label> */}
              <br />
              <Spinner label="กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล..." size={SpinnerSize.large} />
            </div>}
            <br />
            {/* <Fabric> */}
            <Stack 
              horizontal 
              horizontalAlign="center"              
            >
              <Stack vertical style={{ 
                width: '98%', 
                //minWidth: '1070px', 
                maxWidth: '1350px' 
              }}>
                <CommandBar
                  items={commandBarItems}
                  //overflowItems={_overflowItems}
                  //overflowButtonProps={overflowProps}
                  //farItems={_farItems}
                  //ariaLabel="Use left and right arrow keys to navigate between commands"
                />
                <ShimmeredDetailsList
                  items={items || []}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  selectionMode={SelectionMode.none}
                  //selectionMode={SelectionMode.multiple}
                  //getKey="1"
                  //setKey="set"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  //selection={Selection}
                  //selectionPreservedOnEmptyClick={true}
                  //onItemInvoked={download}
                  //enterModalSelectionOnTouch={true}
                  //ariaLabelForSelectionColumn="Toggle selection"
                  //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  //checkButtonAriaLabel="Row checkbox"
                  //onRenderItemColumn={ this.onRenderItemColumn }
                  //listProps={shimmeredDetailsListProps}
                />
              </Stack>
            </Stack>
            {/* </Fabric> */}
            <br/><center>
            {!(documents.length===0 || documents.length < pageSize) && <Pagination
              //selectedPageIndex={page}
              selectedPageIndex={currentPage-1}
              //pageCount={pageCount}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
              previousPageIconProps={{ iconName: 'ChevronLeft' }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
            />}
            
            <br/>
            <br/>

            {/* 
            <br/>
            <a href="http://nav.cx/3p1xabz" target="_blank">
                <img src={PUBLIC_URL + '/images/line-add-friend.png'} alt="เพิ่มเพื่อน" height="36" border="0"></img>
            </a>
            <br/>
            <br/>
            <div>ฝ่ายบริการลูกค้า Line: @leceipt</div>
            <br/>
            <div>โทร. 082-579-8555</div>
            <br/>
            <div>เวลาทำการ 8.30 น. - 17.30 น. ทุกวัน</div>
 */}
            </center>

        </div>
    );

}

export default Documents;