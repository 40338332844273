import React, { useState, useEffect } from 'react';
import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    //Link    
} from 'react-router-dom';

import { MsalProvider, useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { silentRequest } from "./authConfig";
//import { profileRequest } from "./authConfig";

//import { forgotPasswordRequest } from "../authConfig.js";
import { loginRequest } from "./authConfig";
//import { Check } from 'office-ui-fabric-react';

import axios from 'axios';

import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { 
    //calculatePrecision,
    initializeIcons,
    Stack,
    Icon
} from '@fluentui/react';

import Navigation from './components/navigation/Navigation';
import Header from './components/header/Header';
import Documents from './components/documents/documents';
//import Pdf from './components/etax/documents/pdf/Pdf';
//import Xml from './components/etax/documents/xml/Xml';
import Accounts from './components/accounts/Accounts';
import Checks from './components/checks/Checks';
import LogOut from './components/logout/LogOut';
import Loading from './components/loading/Loading';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import ViewPdf from './components/documents/views/pdf/ViewPdf';
import ViewXml from './components/documents/views/xml/ViewXml';
import P12 from './components/certificates/add/p12/P12';

// Register icons and pull the fonts from the default SharePoint cdn.
initializeFileTypeIcons();
initializeIcons();


const API_URL = process.env.REACT_APP_API_URL;

// Styles definition
const stackStyles = {
    root: {
      //background: DefaultPalette.themeTertiary,
      //width: 300,
      background: '#FAF9F8',
      //minHeight: '100vh',
      //height: '100%',
      height: '100vh',
      //height: '500px',
      margin: 0,
      padding: 0,
      minWidth: '1400px',
      minHeight: '100%',
      //overflowY: 'scroll',
      //overflowX: 'hidden',
      
    },
  };


function App({ pca }) {
    return (
        <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                /* authenticationRequest={{
                    redirectStartPage: "http://localhost:3000/",
                }} */
                //errorComponent={ErrorComponent}
                errorComponent={LogOut}
                //errorComponent={{redirectStartPage: "http://localhost:3000/"}}
                loadingComponent={Loading}
            >
                <Body />
    
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
    
}

const Pages = () => {
    
	return (
		
        <Switch>              
            
            <Route exact path="/documents" >                                    
                <ScrollToTop />
                <Documents/>                
            </Route>
            <Route exact path="/accounts" >                                    
                <ScrollToTop />
                <Accounts/>                
            </Route>
            <Route exact path="/documents/views/pdf" >                                    
                <ScrollToTop />
                <ViewPdf/>                
            </Route> 
            <Route exact path="/documents/views/xml" >                                    
                <ScrollToTop />
                <ViewXml/>                
            </Route>
            <Route exact path="/checks" >                                    
                <ScrollToTop />
                <Checks/>                
            </Route>
            <Route exact path="/certificates/add/p12" >                                    
                <ScrollToTop />
                <P12 />
            </Route>
            
            
            <Redirect to="/documents" />
                         
        </Switch>

                        
	)
}

const Body = () =>{    

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [isLoadAccountsComplete, setIsLoadAccountsComplete] = useState(false);
    const [accountData, setAccountData] = useState({});


    useEffect(() => {
        console.log('userEffect retrieve accounts data!');    

        
        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {
            console.log('tokenResponse: ', tokenResponse);

            axios.get(API_URL + '/accounts', 
                {
                headers: { 
                    'Authorization': 'Bearer ' + tokenResponse.accessToken
                } 
                })
                .then((response) => {                   

                    console.log(response);

                    if (response.data) {
                        
                        
                        setAccountData(response.data);
                        
                    } 

                    setIsLoadAccountsComplete(true);

                    //setIsLoading(false);

                    
                }, (error) => {
                    console.log(error);
                
                });           


        }).catch(error => {
           
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

        });

    }, []);

    return (
        
        <Router>
            {!isLoadAccountsComplete 
                ?
                    <div>
                        <Loading />
                        
                    </div>            
            
                :               
                
                    <Stack styles={stackStyles} disableShrink={false} wrap={false} >
                    {/* <Stack styles={{ root: {overflowY: 'hidden', overflowX: 'hidden'} }} disableShrink={false} wrap={false}> */}
                        <Stack horizontal style={{ 
                            //background: 'green', 
                            //minWidth: '1440px' 
                        }} >
                            <Header {...{accountData: accountData}} />
                        </Stack>
                        <Stack horizontal >
                            <Stack vertical style={{ background: 'white', height: '100%', minHeight: 'calc(100vh - 70px)' }} verticalAlign='space-between'>                            
                            {/* <Stack vertical style={{ background: 'white' }} verticalAlign='space-between'> */}
                                
                                    <Navigation />

                                   


                            </Stack>
                            {/* <Stack vertical style={{ background: '#FAF9F8', width: '100%', overflowY: 'scroll', overflowY: 'scroll', height: '91vh' }}> */}
                            <Stack vertical style={{ background: '#FAF9F8', width: '100%' }}>
                                <Stack horizontal horizontalAlign='center' wrap={false}>
                                    <Pages /> 
                                    
                                </Stack>
                                {/* {JSON.stringify(accountData)} */}
                            </Stack>
                        </Stack>
                    </Stack>
            }                        
            
        </Router>
        
    
    );
    
}

export default App;
