import React, { useEffect, useState } from "react";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../authConfig";

import axios from 'axios';

import { useHistory, useLocation } from 'react-router-dom';


import {     
    DefaultButton,
    Stack,
    ActionButton,
    mergeStyles,
    mergeStyleSets,  
    PrimaryButton,
    Spinner,
    FontIcon
} from '@fluentui/react';

import XmlAllViewer from './XmlAllViewer';

import './ViewXml.css';

const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL;

// Processing dialog style
const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4
    }
}

//Check icon style
const checkIconClass = mergeStyles({
    fontSize: 18,    
    //margin: '0 15px',    
    marginTop: '2px',    
    color: 'green'
});

const ViewXml = (props) => {

    const history = useHistory();
    const location = useLocation();

    const [data] = useState(location.state);

    //console.log('data: ', data);

    if (data && ((data.item.key === '') || (data.item.signature === '')) ) {
        backXml();
    }

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});    

    const [xml, setXml] = useState('');
    //const [xml, setXml] = useState('');

    const [certificateName, setCertificateName] = useState(data.item.signature);

    const [loadComplete, setLoadComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    
    const backXml = () => {
        history.push({
            pathname: '/documents',
            //search: '?update=true',  // query string
            state: {}
        })
    }
  
    const openFile = (documentId) => {

        setIsLoading(true);

        instance.acquireTokenSilent({...silentRequest, account: account}).then(tokenResponse => {

            axios.get(ADMIN_API_URL + '/files/' + documentId, 
                {
                    headers: { 
                        'Authorization': 'Bearer ' + tokenResponse.accessToken
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    //console.log(response)              
                    
                    let reader = new FileReader();

                    reader.onload = () => {

                        console.log('Xml :', reader.result);

                        setXml(reader.result);

                        setLoadComplete(true);

                        setIsLoading(false);
                        

                    }

                    reader.readAsText(response.data);
                                        
                    

                    
                }, (error) => {
                    console.log(error);
                });
/* 
            axios.get(ADMIN_API_URL + '/documents/' + documentId, 
                {
                    headers: { 
                        'Authorization': 'Bearer ' + tokenResponse.accessToken
                    }
                })
                .then((response) => {
                    console.log(response)                   
                    
                    setCertificateName(response.data.signatures[0].certificateName);
                    //setEmail(response.data.signatures[0].email);                   
                    
                    
                }, (error) => {
                    console.log(error);
                });
 */
        }).catch(error => {           

            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);  

        });        

    }

    function formatDateTime(date) {
        //let d = new Date(date).toLocaleString("en-GB", {timeZone: "Asia/Bangkok"}),
        //let d = new Date(date),
        let d = new Date(date.toLocaleString("en-GB", {timeZone: "Asia/Bangkok"})),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = '' + d.getHours(),
            minute = '' + d.getMinutes(),
            second = '' + d.getSeconds()
      
        //console.log(d.toString());
      
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hour.length < 2) 
            hour = '0' + hour;
        if (minute.length < 2) 
            minute = '0' + minute;
        if (second.length < 2) 
            second = '0' + second;
      
        return [year, month, day, hour, minute, second].join('');
    }

    const download = () => {
        let time = formatDateTime(data.item.createdTimeValue);

        //console.log('item: ', item);

        let fileName = time + '-' + data.item.type + '-' + data.item.number.replace(/\//g,'') + '.' + data.item.fileExtension;
            

        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([xml]));
        //link.setAttribute('download', item.fileName); //or any other extension
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    useEffect(() => {
        //openFile('fa63460f-ae80-434e-b251-eec870e125e0');
        openFile(data.item.key);

    }, []);


    return (
        <Stack>
            { isLoading &&
            /* { true && */
                <Stack>
                    <Stack horizontal horizontalAlign='center'> 
                        <br/>
                        <br/>
                        <div className="loading-center">
                        <Spinner /* size={SpinnerSize.large} */ label="กำลังโหลดเอกสาร..." labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br/>
                        
                    </Stack>
                </Stack>
            }

            { loadComplete &&
                /* { false && */
                <Stack>
                    {/* <br/>   */}
                    <Stack horizontal horizontalAlign='center' tokens={{ childrenGap: 25 }} style={{ paddingTop: '10px', paddingBottom: '10px', background: '#4D4D4D', width: 'calc(100vw - 303px)', minWidth: '1113px' }}>  
                                
                        {/* <PrimaryButton text="เซ็นเอกสาร" onClick={signPdf}></PrimaryButton>   */}
                        <DefaultButton text="ดาวน์โหลด" onClick={download} style={{ /* fontSize: '20px', height: '50px',  width: '110px'*/ }}></DefaultButton> 
                        <DefaultButton text="กลับ" onClick={backXml} style={{ /* fontSize: '20px', height: '50px', */ width: '110px' }}></DefaultButton> 
                    </Stack>

                    { certificateName && 
                        <Stack horizontal horizontalAlign='center' style={{ paddingTop: '5px', paddingBottom: '5px', background: '#C7E3F8' }}>  
                                    
                            <FontIcon iconName="PenWorkspace" className={checkIconClass} />&nbsp;<FontIcon iconName="SkypeCircleCheck" className={checkIconClass} /><span style={{ marginLeft: '8px' }}>เอกสารถูกลงลายเซ็นดิจิทัล โดย {certificateName}</span>
                        </Stack>
                    }
                    {/* <br/> */}

                    <Stack horizontal horizontalAlign='center'>  
                        <Stack vertical style={{ 
                            width: '100%',   
                            /* minWidth: '1154px', */
                            height: '100%'                          
                            //minWidth: '1070px', 
                            //maxWidth: '1200px' 
                        }}>
                            <div className="all-page-container">          
                                { loadComplete &&        
                                    <XmlAllViewer xml={xml} />
                                }      
                                                             
                            </div>
                        </Stack>
                    </Stack>

                </Stack>
                
            }
        </Stack>
    );

}

export default ViewXml;
